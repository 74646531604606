import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import Sticky from "react-sticky-el";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import backgroundImg from "root/assets/insurance-qualities-background.svg";

import styles from "./index.module.css";

const InsuranceQualities = () => {
  const data = useStaticQuery(graphql`
    query {
      phone1: file(relativePath: { eq: "insurance-qualities-phone1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      phone2: file(relativePath: { eq: "insurance-qualities-phone2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      phone3: file(relativePath: { eq: "insurance-qualities-phone3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      phone4: file(relativePath: { eq: "insurance-qualities-phone4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { scrollYProgress } = useViewportScroll();

  return (
    <div className="stickyBlock">
      <Section>
        <div className={styles.backgroundSection}>
          <img className={styles.backgroundImg} alt="" src={backgroundImg} />
        </div>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <div className={styles.quality1}>
              <div className={styles.qualityText}>
                <Typography variant="h2" color="black" weight="bold">
                  Safer drivers, fewer accidents & claims
                </Typography>
                <div className={styles.qualityTitle}>
                  <Typography color="black">
                    Help drivers understand how their driving behaviours impact
                    their real risk of an accident and accelerate these
                    behavioural changes through gamification and rewards.
                  </Typography>
                </div>
              </div>
              <div className={styles.phoneImgDivMobile}>
                <Img
                  className={styles.phoneImg}
                  alt=""
                  fluid={data.phone1.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
            </div>
            <div className={styles.quality2}>
              <div className={styles.qualityText}>
                <Typography variant="h2" color="black" weight="bold">
                  Better risk segmentation and pricing
                </Typography>
                <div className={styles.qualityTitle}>
                  <Typography color="black">
                    Our scoring system is +3x more powerful than the traditional
                    metrics, helping you offer better prices to safer drivers
                    while reducing up to 40% the costs with claims.
                  </Typography>
                </div>
              </div>
              <div className={styles.phoneImgDivMobile}>
                <Img
                  className={styles.phoneImg}
                  alt=""
                  fluid={data.phone2.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
            </div>
            <div className={styles.quality3}>
              <div className={styles.qualityText}>
                <Typography variant="h2" color="black" weight="bold">
                  From pay-per-mile to full behavior-based insurance
                </Typography>
                <div className={styles.qualityTitle}>
                  <Typography color="black">
                    Launch a groundbreaking car insurance product at your speed,
                    start fast with a simple product and, when prepared, evolve
                    to a full UBI approach.
                  </Typography>
                </div>
              </div>
              <div className={styles.phoneImgDivMobile}>
                <Img
                  className={styles.phoneImg}
                  alt=""
                  fluid={data.phone3.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
            </div>
            <div className={styles.quality4}>
              <div className={styles.qualityText}>
                <Typography variant="h2" color="black" weight="bold">
                  Frequent and meaningful interactions with your drivers
                </Typography>
                <div className={styles.qualityTitle}>
                  <Typography color="black">
                    Offer a set of unique services &amp; in-app features that
                    can help your drivers save time and money, like costs and
                    efficiency monitoring, to build brand awareness &amp; higher
                    user engagement.
                  </Typography>
                </div>
              </div>
              <div className={styles.phoneImgDivMobile}>
                <Img
                  className={styles.phoneImg}
                  alt=""
                  fluid={data.phone4.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <Sticky boundaryElement=".stickyBlock">
              <div className={styles.phoneDivDesktop}>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0, 0.22, 0.23],
                      [1, 1, 0],
                    ),
                    y: useTransform(scrollYProgress, [0, 0.23], [1, 0]),
                  }}
                >
                  <div className={styles.phoneImgDiv}>
                    <Img
                      className={styles.phoneImg}
                      alt=""
                      fluid={data.phone1.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </div>
                </motion.div>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0.22, 0.23, 0.33, 0.34],
                      [0, 1, 1, 0],
                    ),
                    y: useTransform(scrollYProgress, [0.22, 0.34], [1, 0]),
                  }}
                >
                  <div className={styles.phoneImgDiv}>
                    <Img
                      className={styles.phoneImg}
                      alt=""
                      fluid={data.phone2.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </div>
                </motion.div>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0.33, 0.34, 0.45, 0.46],
                      [0, 1, 1, 0],
                    ),
                    y: useTransform(scrollYProgress, [0.33, 0.46], [1, 0]),
                  }}
                >
                  <div className={styles.phoneImgDiv}>
                    <Img
                      className={styles.phoneImg}
                      alt=""
                      fluid={data.phone3.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </div>
                </motion.div>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0.45, 0.46],
                      [0, 1],
                    ),
                    y: useTransform(scrollYProgress, [0.45, 0.46], [1, 0]),
                  }}
                >
                  <div className={styles.phoneImgDiv}>
                    <Img
                      className={styles.phoneImg}
                      alt=""
                      fluid={data.phone4.childImageSharp.fluid}
                      objectFit="cover"
                    />
                  </div>
                </motion.div>
              </div>
            </Sticky>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default InsuranceQualities;
