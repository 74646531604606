import React from "react";

import Typography from "root/components/Typography";
import heroImg from "root/assets/insurance-hero.svg";

import styles from "./index.module.css";

const InsuranceHero = () => {
  return (
    <div>
      <div className={styles.heroSection}>
        <div className={styles.heroContent}>
          <div className={styles.hero}>
            <div className={styles.heroTitle}>
              <Typography variant="h1" color="white" weight="bold">
                Make your car insurance fairer and smarter
              </Typography>
            </div>
            <img className={styles.heroImg} alt="" src={heroImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceHero;
