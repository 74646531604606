import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import InsuranceHero from "root/sections/InsuranceHero";
import InsuranceFeatures from "root/sections/InsuranceFeatures";
import InsuranceQualities from "root/sections/InsuranceQualities";
import HowItWorksGetStarted from "root/sections/HowItWorksGetStarted";
import Footer from "root/sections/Footer";

const METATAGS = {
  title: "Drivit | Insurance",
  description:
    "Drivit is a turnkey driving analytics solution that brings high-quality UBI into the hands of any insurer. Discover what its state of the art technology can bring to your insurance business.",
  keywords:
    "Usage-based insurance, UBI, Driving analytics, Distracted driving, Mileage insurance, Distracted driving tracking, Aggressive driving tracking, Digital car insurance, Telematics, Pay-per-mile, Pay-how-you-drive, Connected car, Driving analytics, Driving SDK, Mobility SDK, Driving white-label, Mobility white-label, Telematics tools, Hardware-free telematics, Machine learning, Artificial intelligence driving analytics, AI driving analytics, Artificial intelligence telematics",
};

const InsurancePage = () => {
  return (
    <div>
      <SEO
        title={METATAGS.title}
        description={METATAGS.description}
        keywords={METATAGS.keywords}
      />
      <Layout>
        <Header currentPage="insurance" background="darkGreen" />
        <InsuranceHero />
        <InsuranceQualities />
        <InsuranceFeatures />
        <HowItWorksGetStarted />
        <Footer />
      </Layout>
    </div>
  );
};

export default InsurancePage;
