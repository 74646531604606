import React from "react";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import featureImg1 from "root/assets/insurance-feature1.svg";
import featureImg2 from "root/assets/insurance-feature2.svg";
import featureImg3 from "root/assets/insurance-feature3.svg";
import featureImg4 from "root/assets/insurance-feature4.svg";
import featureImg5 from "root/assets/insurance-feature5.svg";
import featureImg6 from "root/assets/insurance-feature6.svg";
import featureImg7 from "root/assets/insurance-feature7.svg";
import featureImg8 from "root/assets/insurance-feature8.svg";
import featureImg9 from "root/assets/insurance-feature9.svg";

import styles from "./index.module.css";

const featuresList = [
  {
    feature: "AI-powered risk profiling",
    description:
      "+3x better risk pricing metrics vs. insurance industry standard.",
    image: featureImg1,
  },
  {
    feature: "Behavioural education",
    description:
      "Offer guidance to your drivers on how to be safer on the road.",
    image: featureImg2,
  },
  {
    feature: "Crash detection & analysis",
    description:
      "Instant detection of severe accidents and reconstruction of the details and context.",
    image: featureImg7,
  },
  {
    feature: "Fuel efficiency & costs",
    description:
      "Estimate fuel costs and identify the behaviours that can help drivers save money.",
    image: featureImg5,
  },
  {
    feature: "Station search & route planning",
    description:
      "Fuel & charging stations’ locations and details, recommended according to the route.",
    image: featureImg4,
  },
  {
    feature: "Connected-car compatible",
    description:
      "Seamless support of a growing set of OEMs, used standalone or combined with the app.",
    image: featureImg8,
  },
  {
    feature: "White-label apps or SDKs",
    description:
      "A fast launch with our white-label apps or a tailor-made integration of our native SDKs.",
    image: featureImg3,
  },
  {
    feature: "Fully-fledged analysis tools",
    description:
      "Rich insights and full UBI program control through analytics portal and fast APIs",
    image: featureImg9,
  },
  {
    feature: "GDPR-ready",
    description:
      "Strict compliance with EU’s data privacy policies and best-in-class data security standards.",
    image: featureImg6,
  },
];

const HowItWorksHero = () => {
  return (
    <Section color="lightGrey">
      <div className={styles.title}>
        <Typography variant="h2" color="black" weight="bold">
          Main features &amp; benefits
        </Typography>
      </div>
      <div className={styles.featuresList}>
        {featuresList.map(feature => (
          <div className={styles.feature} key={feature.feature}>
            <img className={styles.featureImage} src={feature.image} alt="" />
            <Typography weight="bold">{feature.feature}</Typography>
            <div className={styles.featureDescription}>
              <Typography>{feature.description}</Typography>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default HowItWorksHero;
